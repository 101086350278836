@use 'src/styles/mixins' as mix;

.main {
  width: 100%;
  height: 100%;
  padding: 100px 0 150px;
  display: flex;
  flex-direction: column;
  z-index: 0;
  position: relative;

  @include mix.vp-1024 {
    padding: 0 0 80px;
  }
}
